<template>
  <div class="col-12 md:col-7">
    <FormKit
      type="form"
      id="salons"
      :actions="false"
      v-model="initValue"
      :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
      @submit="submit"
    >
      <h2 class="tw-text-xl tw-font-bold tw-mb-3">Data Salon</h2>
      <FormKit
        name="name"
        type="text"
        label="Nama Salon"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
      />

      <FormKit
        name="description"
        type="text"
        label="Deskripsi"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
      />

      <h2 class="tw-text-xl tw-font-bold tw-mb-3 tw-mt-5">Jam Operasional</h2>
      <table class="tw-w-full">
        <tr class="tw-bg-gray-200 tw-text-gray-500">
          <th class="tw-text-left tw-w-1/3 tw-p-3 tw-font-medium">Hari</th>
          <th class="tw-text-left tw-w-1/3 tw-p-3 tw-font-medium">Status</th>
          <th class="tw-text-left tw-w-1/3 tw-p-3 tw-font-medium">
            Jam Buka & Tutup
          </th>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Senin</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.monday_open" />
              <span class="">
                {{ schedule.monday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.monday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.monday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Selasa</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.tuesday_open" />
              <span class="">
                {{ schedule.tuesday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.tuesday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.tuesday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Rabu</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.wednesday_open" />
              <span class="">
                {{ schedule.wednesday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.wednesday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.wednesday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Kamis</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.thursday_open" />
              <span class="">
                {{ schedule.thursday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.thursday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.thursday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Jumat</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.friday_open" />
              <span class="">
                {{ schedule.friday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.friday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.friday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Sabtu</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.saturday_open" />
              <span class="">
                {{ schedule.saturday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.saturday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.saturday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>

        <tr class="tw-border-b-2 tw-border-b-gray-100 tw-py-1">
          <td class="tw-p-3 tw-font-medium">Minggu</td>
          <td>
            <div class="tw-p-3 tw-flex tw-items-center tw-gap-2">
              <InputSwitch v-model="schedule.sunday_open" />
              <span class="">
                {{ schedule.sunday_open ? "Buka" : "Tutup" }}
              </span>
            </div>
          </td>
          <td>
            <div class="tw-flex tw-items-center tw-gap-2 tw-p-3">
              <input
                type="time"
                v-model="schedule.sunday_open_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
              <span class="tw-text-gray-400">-</span>
              <input
                type="time"
                v-model="schedule.sunday_close_time"
                class="tw-py-1 tw-px-2 tw-border-2 tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </td>
        </tr>
      </table>

      <h2 class="tw-text-xl tw-font-bold tw-mb-3 tw-mt-5">Alamat</h2>
      <div class="tw-overflow-hidden">
        <GMapMap
          :center="center"
          :zoom="zoom"
          :maxZoom="18"
          map-type-id="terrain"
          class="tw-mt-4 tw-h-72 sm:tw-h-96 tw-w-full tw-relative"
          @click="changeMarker"
          :options="{
            mapTypeControl: false,
            streetViewControl: false,
          }"
        >
          <GMapAutocomplete
            ref="autoCompleteRef"
            placeholder="Search..."
            @place_changed="setPlace"
            class="px-3 py-2 tw-absolute tw-top-2 tw-left-2 tw-rounded-md tw-shadow"
          >
          </GMapAutocomplete>
          <GMapCluster>
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="updateCenter(m.position)"
            />
          </GMapCluster>
        </GMapMap>
      </div>

      <div class="">
        <FormKit
          name="address"
          type="text"
          v-model="address"
          label="Alamat Lengkap"
          placeholder="Masukkan alamat salon"
          validation="required"
          validation-visibility="dirty"
          :classes="{
            ...defaultFormikClass,
            outer: `${defaultFormikClass.outer} tw-mb-0`,
          }"
        />
        <small class="tw-text-gray-400"
          >Apabila lokasi di map kurang akurat, Anda dapat menulis detail alamat
          disini</small
        >
        <FormKit
          name="address_caption"
          type="text"
          v-model="address_caption"
          label="Keterangan Alamat"
          placeholder="Contoh: Gedung Lantai 3"
          validation-visibility="dirty"
          :classes="{
            ...defaultFormikClass,
            outer: `${defaultFormikClass.outer} tw-mb-0`,
          }"
        />
        <div class="tw-flex tw-items-center tw-gap-2">
          <FormKit
            name="province"
            label="Provinsi"
            placeholder="Provinsi"
            validation="required"
            validation-visibility="dirty"
            :classes="defaultFormikClass"
            disabled
            v-model="province"
          />
          <FormKit
            name="city"
            label="Kota"
            placeholder="Kota"
            validation="required"
            validation-visibility="dirty"
            :classes="defaultFormikClass"
            disabled
            v-model="city"
          />
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
          <FormKit
            name="district"
            label="Kecamatan"
            placeholder="Kecamatan"
            validation="required"
            validation-visibility="dirty"
            :classes="defaultFormikClass"
            disabled
            v-model="district"
          />
          <FormKit
            name="post_code"
            label="Kode Pos"
            placeholder="Kode Pos"
            validation="required"
            validation-visibility="dirty"
            :classes="defaultFormikClass"
            v-model="post_code"
          />
        </div>
      </div>

      <FormKit
        name="service_type"
        type="select"
        label="Jenis Layanan"
        validation="required"
        placeholder="Pilih Jenis Layanan"
        :classes="defaultFormikListClass"
        :options="serviceTypes"
      >
        <template #input="context">
          <div class="p-fluid">
            <Dropdown
              v-model="context.value"
              @change="
                (e) => {
                  context.node.input(e.value);
                }
              "
              :options="context.options"
              optionLabel="label"
              optionValue="value"
              :placeholder="context.placeholder"
            >
              <template #option="optionContext">
                <option
                  v-if="!optionContext.option.attrs"
                  :key="optionContext.index"
                  :value="optionContext.option.value"
                >
                  {{ optionContext.option.label }}
                </option>
                <option v-else :key="-optionContext.index" :disabled="true">
                  Not Selected
                </option>
              </template>
            </Dropdown>
          </div>
        </template>
      </FormKit>
    </FormKit>

    <div class="p-fluid tw-mt-12 tw-mb-4">
      <Button label="Simpan" @click="submitData" />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { submitForm } from "@formkit/vue";

import { defaultFormikListClass, defaultFormikClass } from "@/constants/formik";
import { useGeoLocation, useLocationDropdown } from "@/composables/location";
import { useSalonForm } from "@/composables/salon";

const props = defineProps({
  initialData: {
    type: Object,
    required: false,
  },
  initSchedule: {
    type: Object,
    required: false,
  },
});

const initValue = ref(props.initialData);
const schedule = ref(props.initSchedule);

// function selectWeek(date) {
//   return Array(7).fill(new Date(date)).map((el, idx) =>
//     new Date(el.setDate(el.getDate() - el.getDay() + idx)))
// }

const emit = defineEmits(["submit"]);

const {
  center,
  zoom,
  markers,
  lat,
  lng,
  onMarkerChange,
  updateCenter,
  initLatLng,
} = useGeoLocation();

const {
  provinces,
  cities,
  districts,
  getProvinceDropdown,
  getCityDropdown,
  getDistrictDropdown,
} = useLocationDropdown();

const province = ref("");
const city = ref("");
const district = ref("");
const post_code = ref("");

// const open_status = ref(Array(7).fill(false));

async function getAddress(latitude, longitude) {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBgbDBAgirnm3vR-kLCkkRne2cUY0s3qQU`,
    {
      headers: {
        "Accept-Language": "id-ID,id;q=0.9,en-US;q=0.8,en;q=0.7",
      },
    }
  );

  const data = await res.json();

  return data;
}

const autoCompleteRef = ref(null);

const address = ref("");
const address_caption = ref("");

const setPlace = (event) => {
  province.value = event.address_components.find((val) =>
    val.types.includes("administrative_area_level_1")
  ).long_name;
  city.value = event.address_components.find((val) =>
    val.types.includes("administrative_area_level_2")
  ).long_name;
  district.value = event.address_components
    .find((val) => val.types.includes("administrative_area_level_3"))
    .long_name.replace("Kecamatan ", "");
  post_code.value = event.address_components.find((val) =>
    val.types.includes("postal_code")
  ).long_name;

  address.value = event.formatted_address;
  initLatLng(event.geometry.location.lat(), event.geometry.location.lng());
  updateCenter(event.geometry.location);
  zoom.value = 15;
};

const changeMarker = (data) => {
  getAddress(data.latLng.lat(), data.latLng.lng()).then((res) => {
    const data =
      res.results.find((val) => val.types.includes("street_address")) ??
      res.results.find((val) => val.types.includes("route")) ??
      res.results[0];

    address.value = data.formatted_address;
    province.value = data.address_components.find((val) =>
      val.types.includes("administrative_area_level_1")
    ).long_name;
    city.value = data.address_components.find((val) =>
      val.types.includes("administrative_area_level_2")
    ).long_name;
    district.value = data.address_components
      .find((val) => val.types.includes("administrative_area_level_3"))
      .long_name.replace("Kecamatan ", "");
    post_code.value = data.address_components.find((val) =>
      val.types.includes("postal_code")
    ).long_name;

    const city_id = Object.keys(cities.value).find((val) => {
      return (
        cities.value[val]
          .toLowerCase()
          .replace("kota ", "")
          .replace("kabupaten ", "") === city.value.toLowerCase()
      );
    });

    console.info(city_id);
  });
  onMarkerChange(data);
};

onMounted(() => {
  getProvinceDropdown();
  getCityDropdown();
  getDistrictDropdown();
});

const { serviceTypes } = useSalonForm();

const submit = (data) => {
  const province_id = Object.keys(provinces.value).find(
    (key) =>
      provinces.value[key]
        .toLowerCase()
        .replace("dki ", "")
        .replace("di ", "")
        .replace("daerah khusus ibukota ", "")
        .replace("daerah istimewa ", "") ===
      province.value
        .toLowerCase()
        .replace("dki ", "")
        .replace("di ", "")
        .replace("daerah khusus ibukota ", "")
        .replace("daerah istimewa ", "")
  );

  const city_id = Object.keys(cities.value).find(
    (key) =>
      cities.value[key]
        .toLowerCase()
        .replace("kota ", "")
        .replace("kabupaten ", "") ===
      city.value.toLowerCase().replace("kota ", "").replace("kabupaten ", "")
  );
  const district_id = Object.keys(districts.value).find(
    (key) =>
      districts.value[key].toLowerCase().replace("kecamatan ", "") ===
      district.value.toLowerCase().replace("kecamatan ", "")
  );

  const formData = {
    ...data,
    province: parseInt(province_id),
    city: parseInt(city_id),
    district: parseInt(district_id),
    coverage_area: Number(data.coverage_area),
    service_type: Number(data.service_type),
    lat: lat.value,
    long: lng.value,
  };

  emit("submit", formData, schedule.value);
};

watch(
  () => props.initialData,
  (newData) => {
    initLatLng(newData.lat, newData.long);
    initValue.value = {
      province: newData.provinces_name,
      city: newData.cities_name,
      district: newData.districts_name,
      post_code: newData.address.slice(-5),
      ...newData,
      service_type: String(newData.service_type),
    };

    updateCenter({ lat: initValue.value.lat, lng: initValue.value.long });
    zoom.value = 13;
  }
);

watch(
  () => props.initSchedule,
  (newData) => {
    schedule.value = newData;
  }
);

const submitData = () => {
  submitForm("salons");
};
</script>
